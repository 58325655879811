import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CFocalCard,
  CLinkList,
  CustomLink,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'room',
            ja: '客室一覧',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/guestroom/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/guestroom/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宿泊',
                path: '/stay/',
              },
            ],
            current: {
              label: '客室一覧',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect02">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 id="gardenside" className="c_headingLv2">
              美しい庭園がたのしめる
              <br />
              ガーデンサイドルーム
            </h2>
            <p className="c_sectLead">
              緑が広がる美しい庭園がたのしめる。
              <br />
              庭園では結婚式や季節のイベントなどが開催されており、色々なシーンとの出会いもたのしみのひとつです。
            </p>
            <CFocalCard
              data={[
                {
                  link: {
                    href: '/stay/guestroom/terracejuniorsuite/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside.png',
                    alt: 'テラスジュニアスイートツイン',
                  },
                  title: {
                    en: <>terrace junior suite twin</>,
                    ja: <>テラスジュニアスイートツイン</>,
                  },
                  text: (
                    <>
                      ルーフテラスからの景色が美しい
                      <br />
                      季節をたのしむジュニアスイート
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/skyteraccesuite/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside02.png',
                    alt: 'スカイテラススイート',
                  },
                  title: {
                    en: <>sky terrace suite</>,
                    ja: <>スカイテラススイート</>,
                  },
                  text: (
                    <>
                      客室最上階から眺望をたのしむ
                      <br />
                      開放的なリゾートスイート
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/japanesesuite/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside03.png',
                    alt: 'ジャパニーズスイート 和奏',
                  },
                  title: {
                    en: <>Japanese suit</>,
                    ja: <>ジャパニーズスイート 和奏</>,
                  },
                  text: (
                    <>
                      手仕事の温もりが心地良い
                      <br />
                      唯一の和室スイート
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/royalsuite/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside04.png',
                    alt: 'ロイヤルスイート',
                  },
                  title: {
                    en: <>royal suite</>,
                    ja: <>ロイヤルスイート</>,
                  },
                  text: (
                    <>
                      ゆったりとリゾートを満喫
                      <br />
                      洗練されたスイート
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/familycornersuite/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside05.png',
                    alt: 'ファミリーコーナースイート',
                  },
                  title: {
                    en: <>family corner suite</>,
                    ja: <>ファミリーコーナースイート</>,
                  },
                  text: (
                    <>
                      ご家族での滞在もゆったり
                      <br />
                      広々としたスイート
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/noblesuite/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside06.png',
                    alt: 'ノーブルスイート',
                  },
                  title: {
                    en: <>noble suite</>,
                    ja: <>ノーブルスイート</>,
                  },
                  text: (
                    <>
                      アニバーサリーに最適
                      <br />
                      天蓋付きスイート
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/deluxeviewbathtwin/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside07.png',
                    alt: 'デラックスビューバスツイン',
                  },
                  title: {
                    en: <>deluxe view bath twin</>,
                    ja: <>デラックスビューバスツイン</>,
                  },
                  text: (
                    <>
                      美しい景色を眺めて贅沢バスタイム
                      <br />
                      非日常を味わえるビューバスルーム
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/universalroom/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside08.png',
                    alt: 'ユニバーサルルーム',
                  },
                  title: {
                    en: <>universal room</>,
                    ja: <>ユニバーサルルーム</>,
                  },
                  text: (
                    <>
                      車椅子でのご利用も快適
                      <br />
                      バリアフリーのゲストルーム
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/luxuryfamilyroom/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside09.png',
                    alt: 'ラグジュアリーファミリールーム',
                  },
                  title: {
                    en: <>luxury family room</>,
                    ja: <>ラグジュアリーファミリールーム</>,
                  },
                  text: (
                    <>
                      洋室で靴を脱いで過ごせる開放感
                      <br />
                      コネクティング可能なゲストルーム
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/luxurytwin/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside10.png',
                    alt: 'ラグジュアリーツイン',
                  },
                  title: {
                    en: <>luxury twin</>,
                    ja: <>ラグジュアリーツイン</>,
                  },
                  text: (
                    <>
                      大窓から眺める季節の景色
                      <br />
                      開放感溢れるツインルーム
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/luxurydouble/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside11.png',
                    alt: 'ラグジュアリーダブル',
                  },
                  title: {
                    en: <>luxury double</>,
                    ja: <>ラグジュアリーダブル</>,
                  },
                  text: (
                    <>
                      ちょっと贅沢にリゾートステイ
                      <br />
                      シングルにもお薦めのゲストルーム
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/moderatetwin/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_gardenside12.png',
                    alt: 'モデレートツイン',
                  },
                  title: {
                    en: <>moderate twin</>,
                    ja: <>モデレートツイン</>,
                  },
                  text: (
                    <>
                      最もお得に過ごせるガーデンサイド
                      <br />
                      気楽に滞在を楽しめるツインルーム
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
        <section>
          <LWrap>
            <h2 id="forestside" className="c_headingLv2">
              樹々の色彩がたのしめる
              <br />
              フォレストサイドルーム
            </h2>
            <p className="c_sectLead">
              四季折々の樹々の色彩がたのしめる。
              <br />
              東北の美しい季節の景色と気持ち良い爽やかな風が心と身体に癒やしを与えてくれます。
            </p>
            <CFocalCard
              data={[
                {
                  link: {
                    href: '/stay/guestroom/juniorsuitedouble/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_forestside.png',
                    alt: 'ジュニアスイートダブル',
                  },
                  title: {
                    en: <>junior suite double</>,
                    ja: <>ジュニアスイートダブル</>,
                  },
                  text: (
                    <>
                      客室最上階で滞在をたのしむ
                      <br />
                      プライベートなジュニアスイート
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/moderatedouble/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_forestside02.png',
                    alt: 'モデレートダブル',
                  },
                  title: {
                    en: <>moderate double</>,
                    ja: <>モデレートダブル</>,
                  },
                  text: (
                    <>
                      広々としたお部屋で寛ぐ
                      <br />
                      ゆったり過ごせるゲストルーム
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/standardhollywoodtwin/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_forestside03.png',
                    alt: 'スタンダードハリウッドツイン',
                  },
                  title: {
                    en: <>standard hollywood twin</>,
                    ja: <>スタンダードハリウッドツイン</>,
                  },
                  text: (
                    <>
                      自由に選べるベッドスタイル
                      <br />
                      より快適に過ごせるゲストルーム
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/standardtwin/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_forestside04.png',
                    alt: 'スタンダードツイン',
                  },
                  title: {
                    en: <>standard twin</>,
                    ja: <>スタンダードツイン</>,
                  },
                  text: (
                    <>
                      ちょっと優雅にプライベートステイ
                      <br />
                      1~2名さまに最適なゲストルーム
                    </>
                  ),
                },
                {
                  link: {
                    href: '/stay/guestroom/standarddouble/',
                  },
                  img: {
                    src: '/assets/images/stay/guestroom/img_forestside05.png',
                    alt: 'スタンダードダブル',
                  },
                  title: {
                    en: <>standard double</>,
                    ja: <>スタンダードダブル</>,
                  },
                  text: (
                    <>
                      ゆったりくつろぐプライベートステイ
                      <br />
                      少人数にお薦めのゲストルーム
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
